import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Client } from '../../../shared/models/client.model';
import {
  CornerstoneApiResponse,
  HttpOptions,
} from '../../../shared/models/http.model';
import { HttpService } from '../../../shared/services/http.service';
import { ClientService } from '../../clients/services/client.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private httpOptions: HttpOptions;

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private clientService: ClientService
  ) {
    this.httpOptions = this.httpService.getHttpOptions();
  }

  public getTenantClients(tenantId: string): Observable<Client[]> {
    return this.httpClient
      .get<CornerstoneApiResponse<Client[]>>(
        `${environment.nestApiUrl}/tenant/${tenantId}/clients`,
        this.httpOptions
      )
      .pipe(
        map((res) => res.data),
        tap((clients: Client[]) => {
          this.clientService.setClients(clients);
        }),
        catchError((error: HttpErrorResponse) => {
          throw error;
        })
      );
  }
}
