import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from 'src/app/shared/models/client.model';
import { Store } from 'src/app/shared/models/store.model';
import { ClientService } from '../../../features/clients/services/client.service';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { ModalConfig } from '../../../shared/models/modal.config';
import { LayoutType } from '../../config/config';
import { LayoutInitService } from '../../services/layout-init.service';
import { LayoutService } from '../../services/layout.service';
import { TenantService } from './../../../features/tenants/services/tenants.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  @ViewChild('modalClient') public modalComponent: ModalComponent;

  modalConfig: ModalConfig = {
    modalTitle: 'New Client',
    dismissButtonLabel: 'Submit',
    closeButtonLabel: 'Cancel',
    modalSize: 'lg',
  };

  public isClientDropdownShow: boolean = false;
  public clients: Client[] = [];
  public client: Client = new Client();
  public store: Store = new Store();
  public currentClient: Client;
  public isNewClient: boolean = false;
  public isNewStore: boolean = false;
  public isStoreCompleted: boolean = false;
  public linkAuth: string;
  public goToClient: boolean = true;

  constructor(
    private router: Router,
    private layout: LayoutService,
    private layoutInit: LayoutInitService,
    private clientService: ClientService,
    private tenantsService: TenantService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.clientService.currentClient$.subscribe((client) => {
      this.currentClient = client;
      this.cdr.detectChanges();
    });
    this.currentClient = this.clientService.getCurrentClient();

    this.tenantsService.getTenantClients('atomix').subscribe({
      next: (clients) => {
        this.clients = clients;
        this.cdr.detectChanges();
      },
    });
  }

  calculateMenuItemCssClass(url: string): string {
    return checkIsActive(this.router.url, url) ? 'active' : '';
  }

  setBaseLayoutType(layoutType: LayoutType) {
    this.layoutInit.setBaseLayoutType(layoutType);
  }

  setToolbar(
    toolbarLayout: 'classic' | 'accounting' | 'extended' | 'reports' | 'saas'
  ) {
    const currentConfig = { ...this.layout.layoutConfigSubject.value };
    if (currentConfig?.app?.toolbar) {
      currentConfig.app.toolbar.layout = toolbarLayout;
      this.layout.saveBaseConfig(currentConfig);
    }
  }

  public selectClient(client: Client) {
    this.currentClient = client;
    this.clientService.setCurrentClient(this.currentClient);
    this.router.navigate(['/client/stores']);
  }

  public async addClient() {
    this.isNewClient = true;
    return await this.modalComponent.open();
  }

  public onContinueClient(event) {
    this.client.name = event.clientName;
    this.store.type = event.storeType;
    this.isNewClient = false;
    this.isStoreCompleted = false;
    this.isNewStore = true;
  }

  public onContinueStore(event) {
    this.store = event.store;
    this.linkAuth = event.linkAuth;
    this.isNewClient = false;
    this.isNewStore = false;
    this.isStoreCompleted = true;
  }

  public onDone() {
    this.onClose();
  }

  public discard() {
    this.onClose();
  }

  public onClose() {
    this.isNewClient = false;
    this.isNewStore = false;
    this.isStoreCompleted = false;
    this.isNewStore = false;
    this.store = new Store();
    this.client = new Client();
    this.modalComponent.close();
  }

  public isClientRoute() {
    const mainRoute = '/client/';
    if (
      this.router.url.includes(mainRoute) ||
      this.router.url.includes('/admin')
    ) {
      return true;
    } else {
      return false;
    }
  }
}

const getCurrentUrl = (pathname: string): string => {
  return pathname.split(/[?#]/)[0];
};

const checkIsActive = (pathname: string, url: string) => {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
};
